

@media (min-width: 1200px) {
  body .product-list-item::after {
    width: calc(100% + 15px);
  }
  body .product-list .wp-block-column:not(:first-child) .product-list-item::after {
    width: calc(100% + 30px);
    left: -15px;
  }
  body .product-list .wp-block-column:last-child .product-list-item::after {
    width: calc(100% + 15px);
    left: -15px;
  }
  body .product-list .wp-block-column:not(:last-child) .product-list-item::before {
    content: "";
    position: absolute;
    top: 0;
    right: -15px;
    height: 100%;
    border-left: 1px solid #0a3255;
  }
}

@media (min-width: 992px) {
  .layout > .container > .row {
    box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  }
  .layout > .container > .row > .col-lg-8 {
    width: 66%;
    padding: 0;
  }
  .layout > .container > .row > .col-lg-4 {
    width: 34%;
    padding: 0;
  }
}